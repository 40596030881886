<template>
  <div class="row">
    <div class="col-md-12 card">
      <div class="card-body row">
        <div class="col-sm-6">
          <div class="card-header">
        <h5 class="card-title">Pipeline</h5>
      </div>
      </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <div class="btn-group">
              <p-button type="primary" round outline @click="resetTable()">Reset</p-button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body row">
        <div class="col-sm-12 mt-2">
          <div>
            <vue-good-table
              :theme="tableTheme"
              ref="pipelineGrid"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"

              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                mode: 'records',

                perPage: serverParams.perPage,      // set programmatially
                setCurrentPage: serverParams.page,   // set programmatically

                position: 'bottom',
                perPageDropdown: [3, 7, 9],
                dropdownAllowAll: false,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                }"
              :sort-options="{
                enabled: true,
                initialSortBy: serverParams.sort
                //initialSortBy: {field: 'company_name', type: 'desc'} // set programmatically
                }">
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)">
                      <i class="fa fa-edit"></i>
                    </p-button>
                  </span> 
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span> 
                </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import * as CONSTS from '../../../consts/constants'
  var numeral = require("numeral");


  export default{
    components: {


    },
    computed: {
      ...mapGetters(['userSelectedProduction','userSelectedProductionCcy', 'gridStates', 'tableTheme']),
      productionSelected () {
        return this.$store.getters.userSelectedProduction
      }
    },
    mounted () {

      // https://github.com/xaksis/vue-good-table/issues/275
      // this needs to be done properly once this enhancement is made

      // this.$refs.pipelineGrid.columnFilters = this.serverParams.columnFilters
      // this.$refs.pipelineGrid.filterRows(this.serverParams.columnFilters, true)

      this.updateTableFilters()

      // console.log('mounted: gridState', this.$refs.pipelineGrid.columnFilters )
    },
    created () {

      // let gridState = this.$store.getters.getGridState(this.gridName)
      // console.log('mounted: gridState', this.gridStates)

      let gridState = this.gridStates[this.gridName]

      if (gridState){
        this.serverParams = gridState
      }
      this.loadData()
    },
    watch: {
      productionSelected (userSelectedProduction, oldValue) {
        // console.log('Salesbiard productionSelected')
      // userSelectedProduction has changed (loaded)
        this.loadData()
      }
    },
    data () {
      return {
        gridName: 'pipelineGrid',
        serverParams: {
          gridName: 'pipelineGrid',
          columnFilters: {
          },
          sort: {
            field: 'date_booked', type: 'desc'
          },
          page: 1,
          perPage: 10
        },
        columns: [
          {
            field: 'company_name',
            label: 'Client',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter client',
              // filterValue: this.test
            }
          },
          {
            field: 'full_name',
            label: 'Sales Person',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter Sales Person'
            }
          },
          {
            field: 'date_booked',
            label: 'Booking Date',
            formatFn: this.formatDate,
            tdClass: 'tablecell',
            thClass: 'tablecell',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter Sales Person'
            }
          },
          {
            field: 'package_name',
            label: 'Package',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter Sales Person'
            }
          },
          {
            field: 'vendortxcode',
            label: 'Reference',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter Sales Person'
            }
          },
          {
            field: 'price',
            label: 'Price',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            formatFn: this.formatPrice,
            type: 'decimal',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter'
            }
          },
        {
          label: 'Actions',
          field: 'actions',
        }
        ],
        rows: []
      }
    },
    methods: {

      //
      updateTableFilters() {

        for (let i = 0; i < this.columns.length; i++) {
          const col = this.columns[i];
          if (typeof col.filterOptions !== 'undefined') {

            let filterVal = {}

            if ( (filterVal = this.serverParams.columnFilters[ this.columns[i].field ] ) ) {

              let filter = this.columns[i].filterOptions
              filter.filterValue = filterVal
              // seems bonkers having to do this
              let  filterString = JSON.stringify(filter)
              let filterObj = JSON.parse(filterString)

              this.$set(this.columns[i], 'filterOptions', filterObj )
              // this.$set(this.columns[i], 'filterOptions', { enabled: true, filterValue: filterVal })
            }
          }
        }
      },
      resetTable: function() {
        this.$refs['pipelineGrid'].reset()
        this.serverParams.columnFilters = []
        this.$store.dispatch('setGridState', this.serverParams )
      },

      // table events
      onPageChange(params) {
        this.serverParams.page = params.currentPage
        this.$store.dispatch('setGridState', this.serverParams )
      },

      onPerPageChange(params) {
        this.serverParams.perPage = params.currentPerPage
        this.$store.dispatch('setGridState', this.serverParams )
      },

      onSortChange(params) {

        if (params.columnIndex){
        
         
            let newSort = {
             type: params.sortType,
              field: this.columns[params.columnIndex].field,
          }

          this.serverParams.sort = newSort
          this.$store.dispatch('setGridState', this.serverParams )
        }

      },

      onColumnFilter(params) {
        this.serverParams.columnFilters = params.columnFilters
        this.$store.dispatch('setGridState', this.serverParams )
      },

      // end table events

       searchArray: function(array, key, prop){
          // Optional, but fallback to key['name'] if not selected
          prop = (typeof prop === 'undefined') ? 'name' : prop;

          for (var i=0; i < array.length; i++) {
              if (array[i][prop] === key) {
                  return array[i];
              }
          }
          return null
      },

      formatDate (value) {
        return this.$moment(value).format('DD-MM-YYYY')
      },

      formatType: function(value) {
        let obj = this.searchArray(CONSTS.strings.ad_type, value, 'value')
        if (obj) {
          return obj.text
        }
      },
      formatPrice: function(value) {

        let financialFormat = new String('0,0.00')
        return this.userSelectedProductionCcy + numeral(value).format(financialFormat)

      },
      formatExportToAccounts: function(value) {
        let obj = this.searchArray(CONSTS.strings.exportedtosage, value, 'value')
        if (obj) {
          return obj.text
        }
      },
      formatHeat: function(value) {
        let obj = this.searchArray(CONSTS.strings.heat, value, 'value')
        if (obj) {
          return obj.text
        }
      },
      loadData: function () {
        this.$http.get( 'sales/production/pipeline/' + this.userSelectedProduction )

        .then(response => {
          // console.log('data loaded', this.serverParams);

          this.rows = response.data
        })
        .catch(error => {
          // console.log(error)
          this.$notify({
            message: 'There was a problem loading the pipeline. Please try again.',
            type: 'warning'
          })
        })
      },
      handleEdit (index, row) {

          // https://github.com/xaksis/vue-good-table/issues/142
          // access the filtered table data
          // console.log( "myTable", this.$refs.myTable.filteredRows[0].children )

        //  console.log( "pipelineGrid Filters", this.$refs.pipelineGrid.columnFilters)
          // console.log( "pipelineGrid", this.$refs.pipelineGrid.currentPage)
          // console.log( "pipelineGrid", this.$refs.pipelineGrid.sortColumn)
          // console.log( "pipelineGrid", this.$refs.pipelineGrid.sortType)

          // console.log(  this.serverParams )
          // console.log( 'table obj:', this.$refs.pipelineGrid )

          // this.$refs.pipelineGrid.$children[1].updateFiltersImmediately({field: 'company_name'}, 'logo');




          try {
            // this.$router.push({ name: 'Booking', params: { id: row.guid }})

            this.$router.push({
              name: "Booking",
              params: { id: row.guid, bookingmode: CONSTS.PIPELINE }
            });
          }
          catch(e){
            console.log(e)
          }
      }
    }
  }
</script>
<style lang="scss">

  .tablecell {
   font-size: 14px;
 }

</style>
